"use strict";

import { companyData, testimonials } from "./constants";
// Import Flaticon UIcons CSS globally
import '@flaticon/flaticon-uicons/css/all/all.css'; // Adjust this path as per the installed package



const JOS = {
    init: function() {},
};

// Make it global if necessary
window.JOS = JOS;


// ::::: GLobal Javascript ::::
document.addEventListener("DOMContentLoaded", function() {
  // ================================Animate Interaction on Scroll ==================================
  JOS.init({
    // disable: false, // Disable JOS gloabaly | Values :  'true', 'false'
    // debugMode: true, // Enable JOS debug mode | Values :  'true', 'false'
    passive: false, // Set the passive option for the scroll event listener | Values :  'true', 'false'

    once: true, // Disable JOS after first animation | Values :  'true', 'false' || Int : 0-1000
    animation: "fade-up", // JOS global animation type | Values :  'fade', 'slide', 'zoom', 'flip', 'fade-right', 'fade-left', 'fade-up', 'fade-down', 'zoom-in-right', 'zoom-in-left', 'zoom-in-up', 'zoom-in-down', 'zoom-out-right', 'zoom-out-left', 'zoom-out-up', 'zoom-out-down', 'flip-right', 'flip-left', 'flip-up', 'flip-down, spin, revolve, stretch, "my-custom-animation"
    // animationInverse: "static", // Set the animation type for the element when it is scrolled out of view | Values :  'fade', 'slide', 'zoom', 'flip', 'fade-right', 'fade-left', 'fade-up', 'fade-down', 'zoom-in-right', 'zoom-in-left', 'zoom-in-up', 'zoom-in-down', 'zoom-out-right', 'zoom-out-left', 'zoom-out-up', 'zoom-out-down', 'flip-right', 'flip-left', 'flip-up', 'flip-down, spin, revolve, stretch, "my-custom-animation"
    timingFunction: "ease", // JOS global timing function | Values :  'ease', 'ease-in', 'ease-out', 'ease-in-out', 'linear', 'step-start', 'step-end', 'steps()', 'cubic-bezier()', 'my-custom-timing-function'
    //mirror : false, // Set whether the element should animate back when scrolled out of view | Values :  'true', 'false'
    threshold: 0, // Set gloabal the threshold for the element to be visible | Values :  0-1
    delay: 0.5, // Set global the delay for the animation to start | Values :  0,1,2,3,4,5
    duration: 0.7, // Set global the duration for the animation playback | Values :  flota : 0-1 & int : 0,1,2,3,4,5

    // startVisible: "true", // Set whether the element should animate when the page is loaded | Values :  'true', 'false' || MS : 0-10000
    scrollDirection: "down", // Set the scroll direction for the element to be visible | Values :  'up', 'down', 'none'
    //scrollProgressDisable: true // disable or enable scroll callback function | Values :  'true', 'false'
    // intersectionRatio: 0.4, // Set the intersection ratio between which the element should be visible | Values :  0-1 (automaticaly set)
    // rootMargin_top: "0%", // Set by which percent the element should animate out (Recommended value between 10% to -30%)
    // rootMargin_bottom: "-50%", // Set by which percent the element should animate out (Recommended value between -10% to -60%)
    rootMargin: "0% 0% 15% 0%", // Set the root margin for the element to be visible | Values :  _% _% _% _%  (automaticaly set)
  });

    // ======================================== Accordion ======================================
  let accordions = document.querySelectorAll(".accordion-item");
  if(accordions){
    accordions.forEach((item) => {
      let label = item.querySelector(".accordion-header");
      label.addEventListener("click", () => {
        accordions.forEach((accordionItem) => {
          accordionItem.classList.remove("active");
        });
        item.classList.toggle("active");
      });
    });
  }

  //:::::::::::::::::::::::::::::::::::::::::: Template JavaScript ::::::::::::::::::::::::::::::::::

  // ========================TF-1 : Brand Slider================================
  var brandSlider = new Swiper(".brand-slider", {
    slidesPerView: 2,
    spaceBetween: 90,
    speed: 1200,
    autoplay: {
      delay: 4000,
      disableOnInteraction: false,
    },
    breakpoints: {
      768: {
        slidesPerView: 3,
      },
      992: {
        slidesPerView: 4,
      },
      1200: {
        slidesPerView: 5,
      },
    },
  });

  // ========================TF-2 : Brand Slider================================
  var brandSlider = new Swiper(".brand-slider-2", {
    slidesPerView: 2,
    spaceBetween: 90,
    speed: 1200,
    autoplay: {
      delay: 4000,
      disableOnInteraction: false,
    },
    breakpoints: {
      768: {
        slidesPerView: 3,
      },
      992: {
        slidesPerView: 4,
      },
      1200: {
        slidesPerView: 5,
      },
      1400: {
        slidesPerView: 6,
      },
    },
  });
  // ========================TF-3 : Testimonial Slider===============================
  const lazyImages = document.querySelectorAll(".lazy-image");
    //console.log("lazyImages:", lazyImages)
    lazyImages.forEach((img) => {
      img.addEventListener("load", function () {
        const placeholder = img.previousElementSibling;
  
        // Hide the placeholder when the image is fully loaded
        if (placeholder && placeholder.classList.contains("image-placeholder")) {
          placeholder.style.display = "none";
        }
  
        // Fade in the image after it's loaded
        img.style.opacity = 1;
      });
  
      // If the image is already cached (and immediately available), trigger the event manually
      if (img.complete) {
        img.dispatchEvent(new Event('load'));
      }
  
      // Optional: Handle error cases
      img.addEventListener("error", function () {
        console.error("Image failed to load:", img.src);
      });
    });

  // Get all tab buttons and content sections
  const tabButtons = document.querySelectorAll(".tab-button");
  const tabContents = document.querySelectorAll(".tab-content");
  
  // Add click event listeners to tab buttons
  tabButtons.forEach((button) => {
    button.addEventListener("click", () => {
      // Remove 'active' class from all tab buttons
      tabButtons.forEach((btn) => {
        btn.classList.remove("active");
      });
  
      // Add 'active' class to the clicked button
      button.classList.add("active");
  
      // Get the data-tab attribute of the clicked button
      const tabId = button.getAttribute("data-tab");
  
      if (tabId === "show-all") {
        // Show all content if "Show All" is clicked
        tabContents.forEach((content) => {
          content.classList.remove("hidden");
        });
      } else {
        // Hide all tab content first
        tabContents.forEach((content) => {
          content.classList.add("hidden");
        });
  
        // Show only the content sections that match the clicked button's tabId
        document.querySelectorAll(`.${tabId}`).forEach((content) => {
          content.classList.remove("hidden");
        });
      }
    });
  });

  // ========================TF-1 : Testimonial Slider================================
  const testimonialSlider = new Swiper('.testimonial-slider', {
    // Optional parameters
    slidesPerView: 1,
    loop: true,
    spaceBetween: 30,

    // If we need pagination
    pagination: {
      el: '.swiper-pagination',
      clickable: true,
    },
  });

  // ======================== All function called ================================
  const isHightlightsVisible =  createHighlights();
});

function toggleSwitch(toggleElement, monthId, hourId) {
  var month = document.querySelector(`.${monthId}`);
  var annual = document.querySelector(`.${hourId}`);

  if (toggleElement.checked) {
    month.classList.add("hidden");
    annual.classList.remove("hidden");
  } else {
    month.classList.remove("hidden");
    annual.classList.add("hidden");
  }
}

function createHighlights() {
  const key_highlights = companyData.company.key_highlights;

  if(key_highlights){
    const container = document.getElementById('key-highlights-container');
  
    if(container){
      container.innerHTML = ''; // Clear any existing content
      key_highlights.forEach(item => {
        // Create the main div with class 'scroll-item'
        const scrollItemDiv = document.createElement('div');
        scrollItemDiv.className = 'scroll-item';
  
        // Create icon element
        const icon = document.createElement('i');
        icon.className = item.iconClass;
  
        // Create text span
        const span = document.createElement('span');
        span.className = 'inline-block';
        span.textContent = item.title;
  
        // Append icon and span to the scroll-item div
        scrollItemDiv.appendChild(icon);
        scrollItemDiv.appendChild(span);
  
        // Append the scroll-item div to the container
        container.appendChild(scrollItemDiv);
      });
    }
  }
}

// Function to dynamically generate testimonial slides
function generateTestimonials(testimonials) {
  if(testimonials){
    const swiperWrapper = document.querySelector('#swiper-wrapper-testimonials');
    if(swiperWrapper){
      swiperWrapper.innerHTML = "";
      console.log("swiperWrapper:", swiperWrapper);
      testimonials.forEach((testimonial, index) => {
        const slide = document.createElement('div');
        slide.className = `swiper-slide ${index === 0 ? 'swiper-slide-active' : ''}`;
        slide.setAttribute('role', 'group');
        slide.setAttribute('aria-label', `${index + 1} / ${testimonials.length}`);
        slide.style.width = '1300px'; // Example width, adjust as necessary
  
        // Construct the inner HTML for the slide
        slide.innerHTML = `
            <div class="flex flex-col items-center gap-12 px-0 md:flex-row md:gap-16 md:px-8 lg:gap-[90px] lg:px-[60px]">
                <div class="h-[230px] w-[230px] overflow-hidden rounded-[50%] border-[10px] border-ColorDenimDarkBlue">
                    <img src="${testimonial.picture}" alt="${testimonial.name} picture" width="230" height="230" class="h-full w-full object-cover">
                </div>
                <div class="flex-1 text-ColorOil">
                    <blockquote class="mb-10 text-xl font-semibold -tracking-[0.5px] lg:text-2xl">
                        “${testimonial.feedback}”
                    </blockquote>
                    <div>
                        <span class="block text-xl font-semibold">~ ${testimonial.name}</span>
                        <span class="block">${testimonial.designation}</span>
                    </div>
                </div>
            </div>
        `;
  
        // Append the generated slide to the swiper wrapper
        swiperWrapper.appendChild(slide);
      });
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
}

const insertTestimonials = generateTestimonials(testimonials);
if (insertTestimonials) {
  console.log("insertTestimonials:", insertTestimonials);
  var testimonialOne = new Swiper(".testimonial-one", {
    slidesPerView: 1,
    loop: true,
    speed: 1000,
    autoplay: {
      delay: 3000,
      disableOnInteraction: false,
    },
    navigation: {
      nextEl: "#slider-nav-btn-next",
      prevEl: "#slider-nav-btn-prev",
    },
  });

  document.querySelector('#slider-nav-btn-next').addEventListener('click', () => {
    testimonialOne.slideNext();
  });
  
  document.querySelector('#slider-nav-btn-prev').addEventListener('click', () => {
    testimonialOne.slidePrev();
  });

  // The setInterval is redundant because Swiper has autoplay enabled
  // Simulate autoplay by clicking next button every 3 seconds
  setInterval(() => {
    testimonialOne.slideNext();
  }, 3000); // Change slide every 3000 milliseconds (3 seconds)
}



const carouselElement = document.getElementById('carousel-example');

const items = [
    {
        position: 0,
        el: document.getElementById('carousel-item-1'),
    },
    {
        position: 1,
        el: document.getElementById('carousel-item-2'),
    },
    {
        position: 2,
        el: document.getElementById('carousel-item-3'),
    },
    {
        position: 3,
        el: document.getElementById('carousel-item-4'),
    },
];

// options with default values
const options = {
    defaultPosition: 1,
    interval: 3000,

    indicators: {
        activeClasses: 'bg-white dark:bg-gray-800',
        inactiveClasses:
            'bg-white/50 dark:bg-gray-800/50 hover:bg-white dark:hover:bg-gray-800',
        items: [
            {
                position: 0,
                el: document.getElementById('carousel-indicator-1'),
            },
            {
                position: 1,
                el: document.getElementById('carousel-indicator-2'),
            },
            {
                position: 2,
                el: document.getElementById('carousel-indicator-3'),
            },
            {
                position: 3,
                el: document.getElementById('carousel-indicator-4'),
            },
        ],
    },

    // callback functions
    onNext: () => {
        console.log('next slider item is shown');
    },
    onPrev: () => {
        console.log('previous slider item is shown');
    },
    onChange: () => {
        console.log('new slider item has been shown');
    },
};

// instance options object
const instanceOptions = {
  id: 'carousel-example',
  override: true
};